@font-face {
  font-family: "Optima";
  src: url("./assets/fonts/Optima.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Optima";
  src: url("./assets/fonts/Optima-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.hideNavLinks {
  display: none !important;
}

:target:before {
  content: "";
  display: block;
  height: 110px;
  margin: -100px 0 0;
}

body {
  font-family: "Optima", sans-serif !important;
  margin: 0;
  padding-top: 60px;
  color: #1b1e2f;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale;
}

h4 {
  display: block;
  font-size: 1em !important;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}

h3 {
  display: block;
  font-size: 1.17em !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}


h2 {
  display: block;
  font-size: 1.5em !important;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold !important;
}

.title {
  text-align: center;
  font-weight: bold;
  margin: 20px 0 15px;
  color: #1b1e2f;
  font-size: 22px;
}

.sub-title {
  text-align: center;
  font-size: 14px;
}

.title-image {
  height: 30px;
}

hr {
  background-color: blue;
}

header {
  width: 100%;
  height: 60px;
  background-color: #1b1e2f;
  position: fixed;
  top: 0;
  z-index: 1;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px 0 !important;
  /* box-shadow: 0 4px 4px #888; */
}

.youtube-video-ratio {
  position: relative;
  padding-bottom: 56.25%;
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-video-container {
  padding: 0 10px 10px;
  max-width: 1120px;
  margin: auto;
}

.youtube-app-link-description {
  text-align: center;
  margin: 10px 0;
}

.youtube-app-desc {
  color: #1b1e2f !important;
  margin: auto 0px !important;
}

.tile {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
  color: #1b1e2f;
  font-family: Optima;
  margin: 30px 0 0;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(55, 55, 55, 1);
  -moz-box-shadow: 0px 0px 1px 0px rgba(55, 55, 55, 1);
  box-shadow: 0px 0px 1px 0px rgba(55, 55, 55, 1);
  max-width: 360px;
  border-radius: 5px;
}

.tiles-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  margin-bottom: 50px
}

.border-line {
  border-bottom: 1px solid #e0e0e0!important;
  width: 90%;
  margin: 0 auto;
  padding-top: 25px;
  margin-bottom: 25px;
}

.divider-line {
  border-bottom: 1px solid #e0e0e0!important;
  max-width: 1080px;
  margin: auto;
}

.time-separator {
  display: none;
}

.tile-text {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
  align-items: center;
  flex: 1 0 auto;
}

.tile-text p {
  margin-top: 0;
  margin-bottom: auto;
  color: #1b1e2f;

  font-size: 14px;
}

.tile-image {
  width: 100%;
  overflow: hidden;
  height: auto;
  max-height: 540px;
}

.tile-title {
  text-align: center;
  align-items: center;
  color: #1b1e2f;
}

.sectionCont {
  text-align: center;
  align-items: center;
  padding: 80px 0;
}

.sectionCont p{
  margin: 5px;
}

.sectionCont h2, .sectionCont h1{
  margin-top: 0px;
  margin-bottom: 30px;
}

.sectionCont h3 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  font-family: Optima;
  font-size: 1.17em;
}

.left-align {
  text-align: left;
}

.left-align p {
  padding-left: 0px !important;
}

.schedule {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px 10px;
}

.schedule p {
  margin: 5px;
}

#announcements p {
  margin: 10px;
}

.schedule h3 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  font-family: Optima;
  font-size: 1.17em;
}

.link {
  cursor: pointer;
  text-decoration: none;
  background-color: white;
  color: #1b1e2f;
  max-width: 160px;
  padding: 8px 15px;
  font-size: 13px;
  justify-self: flex-end;
  margin: 20px 0;
  border: 1px solid #1b1e2f;
  border-radius: 3px;
  transition: all .2s;
}

.link.active {
  color: white;
  background-color: #1b1e2f;
}

.link.active:hover {
  background-color: white;
  color: #1b1e2f;
}

.link:hover {
  color: white;
  background-color: #1b1e2f;
}

.event {
  font-weight: bold;
  color: #136cce;
}

.gridCont {
  width: 100%;
  padding-top: 10px;
}

.gridCont p {
  margin: 20px 0;
  padding: 0 20px;
}

.gridCol {
  margin: 20px 0;
}

.gridCol h3 {
  margin-top: 0;
}

.gridCol .btn, .modalCont .btn-primary, .btn-main, .sessions-tab .btn, .past-sessions .btn, .defaultBtn, .checkinForm .btn, .btn-brand {
  background: #1b1e2f !important;
  border-color: #1b1e2f;
}

.sessions-tab .btn {
  margin-bottom: 20px;
}

.past-sessions .btn {
  margin-top: 15px;
}

.past-sessions p{
  margin-top: 45px;
}

.gridCol .btn:hover, .modalCont .btn-primary:hover, .btn-main:hover, .sessions-tab .btn:hover, .past-sessions .btn:hover, .defaultBtn:hover, .checkinForm .btn:hover, .btn-brand:hover {
  background: white !important;
  color: black;
  border-color: black;
}

.btn-main {
  margin: 20px 0;
}

.eventBanner .gridCol {
  margin: 0px;
}

.imgCol {
  display: flex;
}
.imgCol img {
  margin: auto;
  width: 80%;
}


/* URL Event RSVP CSS */
.customURLEvent {
  overflow: hidden;

  .modal-body {
    padding: 0px;
  }

  .modalHeader {
    z-index: 1111;
    position: relative;
    background: white;
    padding: 25px 10px;

    h2 {
      margin: 0;
      padding: 20px 0px;
    }
    p {
      margin: 0px;
    }
  }

  iframe  {
    z-index: -1;
    margin-top: -220px;
    max-height: 100vh;
    border: none;
  }
}



/* Cards for banner events */
.cardCont {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardCont .card {
  
  /* border: none; */
  margin: 0px 25px;
  margin-bottom: 40px;
  width: 45%;
  /* width: 80%; */
  display: inline-block;
}

.cardCont img {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  width: 120px;
}

.cardCont .btn {
  background: #1b1e2f !important;
  border-color: #1b1e2f;
}

@media (max-width: 1080px) {
  .cardCont .h5 {
    font-size: 1.1rem;
  }
  .cardCont p, .cardCont .btn {
    font-size: .9rem;
  }

  .cardCont img {
    height: 170px;
    width: 112px;
  }

}

@media (max-width: 890px) {
  .cardCont .h5 {
    font-size: 1rem;
  }
  .cardCont {
    display: block;
    margin-top: 50px;
    width: 100%;
  }
  .cardCont .card {
    width: 400px;
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

.footer-image {
  width: 250px;
}

footer {
  background-color: #1b1e2f;
  color: white;
  font-family: Arial;
  display: flex;
  justify-content: center;
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 5px;
  width: 100%;
}

.social-icons-container {
  margin: 0 auto;
  display: flex;
  width: 170px;
  justify-content: center;
}

.footer-social-icon {
  width: 30px;
  height: 30px;
}

.footer-title {
  margin: 30px 0 10px;
}

.footer-charity-container {
  display: flex;
  align-items: flex-end;
}

.footer-charity-text-container {
  text-align: center;
  font-size: 10px;
  font-family: Arial;
  line-height: 15px;
  margin: 20px 0;

}

.footer-charity-text-container p {
  margin: 0;
}

.akshar-deri {
  width: 30px;
  margin: 0 10px;
}

.whatsapp-app-icon {
  width: 20px;
  height: 20px;
  margin: 18.75px 0 5px 10px;
}
.youtube-app-icon {
  width: 25px;
  height: 17px;
  margin: auto 10px auto;
}

.footer-links-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
}

.footer-links-container a {
  margin-top: 15px;
  color: inherit;
  text-decoration: none;
}

.footer-links-spacer {
  flex-grow: 1;
}

.image-link {
  text-align: center;
  text-decoration: none;
}

@media (min-width: 600px) {
  .time-separator {
    display: inline-block;
  }
  .mobile-break {
    display: none;
  }
}

@media (min-width: 750px) {
  .footer-image {
    width: 350px;
  }

  .social-icons-container {
    display: flex;
    width: 200px;
  }

  .schedule {
    padding: 10px calc((100% - 900px) / 2);
  }

  .youtube-video-container {
    padding: 0 20px 10px;
  }

  .footer-social-icon {
    width: 40px;
    height: 40px;
  }

  .whatsapp-app-icon {
    width: 30px;
    height: 30px;
    margin: 12px 0 5px 8px;
  }

  .youtube-app-icon {
    width: 30px;
    height: 21px;
    margin: auto 10px auto;
  }

  .title-image {
    height: 40px;
  }

  .footer-links-container {
    flex-direction: row-reverse;
    align-items: flex-end;
    margin: unset;
    width: 100%;
  }

  .footer-links-container a {
    margin: 0 15px;
    font-size: 12px;
  }

  .footer-title {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .gridCol:first-child {
    border-right: 1px solid #e0e0e0;
  }
}
@media (min-width: 991px) {
  .btn-group-pay {
    width: 50%;
    margin: 0 auto;
  }
}

@media (min-width: 800px) {
  .tiles-container {
    padding: 10px;
    flex-wrap: unset;
    border-radius: 4px;
  }
  .tile {
    max-width: 600px;
    max-height: 540px;
    margin: 10px;
  }
  .tile-title {
    font-size: 22px;
    text-align: center;
    align-items: center;
  }
  .title {
    margin: 40px 0 20px;
    font-size: 32px;
  }
}

@media (min-width: 1080px) {
  .tiles-container {
    background-color: unset;
  }
  .tile {
    width: 333px;
  }
}


/* Events CSS */
.bannerCont img {
  width: 100%;
  padding: 0px 25px;
}
.bannerCont p {
  padding: 0px 25px;
  text-align: center;
  margin-top: 40px;
  font-size: 15px;
}
.event-wrap ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.event-wrap {
    background-image: url("./assets/images/divider.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    max-width: 845px;
    padding: 100px 0 0;
    position: relative;
}
.event-wrap ul li {
    position: relative;
    padding-bottom: 60px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 50px;
}
.event-wrap .list-fire {
  background-image: url("./assets/images/fire.png");
}
.event-wrap .list-tabla {
  background-image: url("./assets/images/tabla.png");
}
.event-wrap .list-100 {
  background-image: url("./assets/images/100.png");
}
.event-wrap .list-kdc {
  background-image: url("./assets/images/diwali-lamp.png");
}
.event-wrap .list-diwali {
  background-image: url("./assets/images/open-book.png");
}
.event-wrap .list-fireworks {
  background-image: url("./assets/images/fireworks.png");
}
.event-wrap .list-annakut {
  background-image: url("./assets/images/sweets.png");
}
.event-wrap .list-exhibition {
  background-image: url("./assets/images/idea.png");
}
.event-wrap .list-samaiyo {
  background-image: url("./assets/images/pray.png");
}

.event-single img {
    height: 300px;
    width: 360px;
    object-fit: cover;
}
.event-content {
    margin-left: 43px;
    text-align: left;
}
.event-content h3 {
  margin-top: 0px;
}
.event-content p {
  font-size: 15px;
  padding: 0px;
}
.event-single.clearfix:nth-child(2n+0) .event-content {
    margin-left: 0;
    margin-right: 45px;
    text-align: right;
}
.event-single.clearfix:nth-child(2n+0) .event-content > p {
    line-height: 20.98px;
}
.event-single.clearfix:nth-child(2n+0) img {
    margin-left: 45px;
}

.event-single.clearfix:nth-child(2n+1) img {
  margin-right: 45px;
  float: right;
}

.eventCont .btn, .modalCont .btn-secondary {
  background: white !important;
  color: black;
  border-color: black;
  margin-right: 5px;
  margin-top: 10px;
}

.eventCont p {
  margin-left: 0px;
}

.eventCont .btn:hover {
  background: #1b1e2f !important;
  border-color: #1b1e2f;
  color: white;
}

.eventCont .card {
  margin: 0 auto;
  margin-bottom: 40px;
  width: 80%;
}

.eventCont .card-body {
  text-align: left;
}

.eventStack {
  display: none;
  text-align: left;
}

.eventStack p {
  padding: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .event-single img {
      height: 210px;
      width: 280px;
  }
  .event-wrap ul li {
    padding-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .eventStack {
    display: block;
  }
  .eventTimeline {
    display: none;;
  }
}

.modalCont h2{
  text-align: center;
}
.modalCont p{
  font-size: 14px;
  text-align: center;
}
.modalHeader {
  margin-bottom: 40px;
}
.modalCont label, .checkinForm label{
  font-size: 14px;
  font-weight: bold;
}
.modalCont sup {
  color: red;
}
.form-check {
  margin: 7px 0;
}
.form-check label {
  font-weight: normal;
  margin-left: 35px;
  font-size: 15px;
}
.form-check input {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
form .hideOption {
  display: none;
}
.modalCont .form-control, .alert{
  font-size: 14px;
}
select {
  background-image: url("./assets/images/dropdown-chevron-black.png");
  background-size: 16px;
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none !important;
  padding-right: 40px;
}

.modalCont .modal-header {
  padding: 0;
  border: 0;
}

.modalCont .close {
  font-family: serif;
  position: absolute;
  right: 0;
  margin-right: 1px;
  margin-top: 1px;
  display: none;
  z-index: 1;
}

@media (max-width: 991px) {
  .modalCont .close {
    display: block;
  }
  .modalCont .mr-auto {
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=password] {
  font-family: Arial;
  letter-spacing: 2px;
  font-size: 18px;
}

input[type=password]::placeholder {
  font-family: "Optima";
  letter-spacing: normal;
  font-size: 1rem;
}

.btn-group-pay .btn{
  margin: 0 auto;
}
.btn-group-pay {
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.btn-group-pay div {
  padding: 5px 0px;
}
.grid-event-img {
  width: 100%;
}

.speaker-img {
  width: 200px;
  border-radius: 5%;
}

video {
  height: 300px;
}

.centerCont {
  display: flex;
}
.centerCont div {
  margin: auto;
}
.centerCont .btn {
  margin-bottom: 20px;
}

.iframeCont iframe{
  width: 100%;
  height: 610px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 10px;
}

.iframeContSpotify iframe{
  width: 100%;
  height: 154px;
}

.iframesCont {
  padding: 10px;
  max-width: 500px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .iframeCont iframe{
    width: 100%;
    height: 500px;
  }
}
@media (max-width: 767px) {
  .iframeCont iframe{
    width: 400px;
    height: 600px;
  }
}
@media (max-width: 600px) {
  .iframeCont iframe{
    width: 100%;
    height: 475px;
  }
}

.parkingCont img {
  width: 75%;
}
.parkingCont {
  border-right: 0px !important;
}
.pujanCont .btn {
  margin: 5px 20px;
}


/* Timeless Hindu Wisdom */

.navbar {
  background-color: #1b1e2f;
}
.navbar .nav-link {
  color: rgb(219, 219, 219) !important;
  transition: color .2s;
}
.navbar .nav-link:hover {
  color: #fff !important;
}

.navbar .center-align {
  margin: 0 auto;
}

.headerSection {
  height: 800px;
  margin-top: -10px;
  padding: 10px;
  background:linear-gradient(rgba(27, 30, 47, 0.75), rgba(27, 30, 47, 0.8)), url('./assets/images/timeless-background.jpeg');
  background-size: cover;
  background-position: top center;
  -webkit-clip-path: ellipse(100% 100% at 50% 0%);
  clip-path: ellipse(120% 100% at 50% 0%);
  background-attachment: fixed;
}

.studentSection {
  height: 800px;
  margin-top: -10px;
  padding: 10px;
  background:linear-gradient(rgba(18, 17, 29, 0.8), rgba(9, 9, 15, 0.8)), url('./assets/images/campus-diwali-background.jpg');
  background-size: cover;
  background-position: bottom center;
  -webkit-clip-path: ellipse(100% 100% at 50% 0%);
  clip-path: ellipse(120% 100% at 50% 0%);
  background-attachment: fixed;
}

.professionalSection {
  height: 800px;
  margin-top: -10px;
  padding: 10px;
  background:linear-gradient(rgba(27, 30, 47, 0.85), rgba(27, 30, 47, 0.85)), url('./assets/images/professional-seminar-background.jpg');
  background-size: cover;
  background-position: bottom center;
  -webkit-clip-path: ellipse(100% 100% at 50% 0%);
  clip-path: ellipse(120% 100% at 50% 0%);
  background-attachment: fixed;
}

.youthSection {
  height: 800px;
  margin-top: -10px;
  padding: 10px;
  background:linear-gradient(rgba(27, 30, 47, 0.75), rgba(27, 30, 47, 0.8)), url('./assets/images/youth-seminar-background.jpg');
  background-size: cover;
  background-position: center center;
  -webkit-clip-path: ellipse(100% 100% at 50% 0%);
  clip-path: ellipse(120% 100% at 50% 0%);
  background-attachment: fixed;
}

.studentSection .actionBtn, .professionalSection .actionBtn, .youthSection .actionBtn {
  width: 200px;
  border: 1px solid white;
  background: transparent;
  margin-top: 15px;
  height: 40px;
  transition: all .2s;
}

.studentSection .actionBtn:hover, .professionalSection .actionBtn:hover, .youthSection .actionBtn:hover {
  border: 1px solid #1b1e2f;
  background: white;
  color: #1b1e2f;
}

.headerContent {
  left: 0;
  right: 0;
  top: 450px;
  z-index: 1;
  text-align: center;
  position: absolute;
  padding-bottom: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #fff;
  padding: 0px 20px;
}

.headerContent h1 {
  font-weight: bold;
  margin: 15px 0;
  font-size: 3em;
}

.headerContent p {
  margin: 15px 0;
  font-size: 18px;
}

.parallaxSection {
  background:linear-gradient(135deg, rgba(27, 30, 47, 0.8), rgba(27, 30, 47, 0.8)), url('./assets/images/timeless-parallax.jpeg');
  background-position: center;
  background-attachment: fixed;
  padding: 100px 0;
  color: #fff;
}

.parallaxSection img {
  opacity: .75;
}

.visitorCont .sessionsInfoCont img {
  width: 50px;
  margin-top: 30px;
}

.sessions-tab {
  nav {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;

    a {
      min-width: 150px
    }
  }
}

.sessions-tab .tab-content{
  text-align: left;
  padding: .5rem 1rem;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding-bottom: 50px;
  padding-top: 30px;
}

.sessions-tab p {
  padding-left: 0px;
}

.sessions-tab a.active {
  color: #1b1e2f !important;
  border-bottom: 0px;
}

.sessions-tab a {
  color: #2d303f !important;
}

.locationCont li {
  margin: 10px 0;
}

.locationCont .carousel-caption {
  padding: 0px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.8);
}

.locationCont .carousel-caption p {
  padding: 5px;
  margin: 0px;
}


.locationCont .carousel {
  padding: 20px 0;
}

.accordion {
  margin-bottom: 25px;
}

.accordion .card {
  border-bottom: 0px;
  border-radius: 0px;
}

.accordion .card-header{
  background: none;
  background-image: url("./assets/images/dropdown-chevron-black.png");
  background-size: 16px;
  background-position: calc(100% - 10px);
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none !important;
}


.accordion .card:last-child{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.accordion .card:first-child{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media (max-width: 600px) {
  .headerSection {
    height: 500px;
    background-attachment: scroll;
  }
  .studentSection, .professionalSection, .youthSection {
    height: 650px;
    background-attachment: scroll;
  }
  .headerSection h1, .professionalSection h1, .studentSection h1, .youthSection h1 {
    font-size: 2em;
  }
  .headerContent {
    top: 300px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
  .parallaxSection {
    background-attachment: scroll;
  }
}

.rsvpSearchCont {
  margin-top: 50px;
}

.rsvpSearchCont .searchInputGroup {
  justify-content: center;
  margin-top: 15px;
}

.rsvpSearchCont .searchInputGroup input{
  border-right: 0px;
  width: 100%;
  max-width: 325px;
}

.rsvpSearchCont .searchInputGroup select{
  border-right: 0px;
  width: 100%;
  max-width: 325px;
  -webkit-appearance: none;
  appearance: none;
}

.rsvpSearchCont .searchInputGroup button{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 100px;
  background: #1b1e2f !important;
  border-color: #1b1e2f;
}
.rsvpSearchCont .searchInputGroup button:hover{
  background: #fff !important;
  color: #1b1e2f;
  border-color: #1b1e2f;
}

.rsvpFormCont {
  margin-top: 50px;
}
.rsvpFormCont table {
  margin-top: 15px;
}
.rsvpFormCont .table:nth-child(2) {
  margin-bottom: 50px;
}
.rsvpFormCont table td, .rsvpFormCont table th {
  white-space: nowrap;
}
.rsvpFormCont table td img{
  width: 15px;
}

.rsvpFormCont table td a{
  cursor: pointer;
  text-align: center;
  display: block;
}

.familyTable tr {
  text-align: left;
}

.familyTable tr {
  text-align: left;
}

.noDataRow {
  text-align: center;
}

.familyTable th:nth-child(1), .familyTable th:nth-child(2) {
  width: 48%;
}

.nameCont .col:first-child {
  padding-left: 0px;
}
.nameCont .col:last-child {
  padding-right: 0px;
}

@media (max-width: 460px) {
  .searchInputGroup {
    display: block;
  }
  .rsvpSearchCont .searchInputGroup input{
    border: 1px solid #ced4da;
    border-radius: 0.25rem !important;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .rsvpSearchCont .searchInputGroup button{
    border-radius: 0.25rem !important;
  }
}

.fullHeight {
  height: 80vh;
}

.checkinForm {
  width: 90%;
  max-width: 460px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
}

.checkinForm .btn {
  margin: 0 auto;
  width: 150px;
  display: block;
  margin-top: 25px;
}
.checkinForm input {
  width: 100%;
  display: block;
  margin: 0 auto;
}

@media (min-width: 991px) {
  .eventList .card {
    flex-direction: row;
    width: 100%;
  }
  
  .eventList .card img{
    width: 30%;
    object-fit: cover;
    display: inline-block;
    margin-right: 25px;
    border-radius: 0px;
    border-top-left-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .eventList .card .card-body{
    width: 70%;
    text-align: left;
    padding-bottom: 35px;
  }
}

.eventList .card .dateCont {
  width: 50px;
  height: 70px;
  background: linear-gradient(to top, #09203f 0%, #537895 100%);
  color: #fff;
  position: absolute;
  margin-left: 10px;
  border-radius: 0px 0px 5px 5px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 25px;

  span {
    font-size: 25px;
    font-weight: bold;
  }
}

.zoneDropdownCont {
  // border: 1px solid #e0e0e0;
  border-radius: 50px;
  box-shadow: 0 3px 30px rgba(38,33,30,.14901960784313725);
  padding: 10px 25px;

  @media (max-width: 991px) {
    border-radius: 10px;
    margin: 5px 25px;
  }

  select, input {
    height: 45px;
  }

  .btn-brand {
    height: 45px;
    width: 100%;
    max-width: 150px;
  }
}

.viewEditCont {
  display: flex;
  position: relative;

  h2 {
    width: 100%;
    padding: 5px 0px;
  }

  .btn-group {
    position: absolute;
    right: 0;

    .btn {
      color: gray;
      background-color: transparent;
      width: 40px;

      &.active {
        background: #1b1e2f;
        color: white;
        border-color: #1b1e2f;
      }
    }
  }
}

.padharamniTable {
  text-align: left;

  thead tr:first-child th {
    border-top: 0px;
  }

  button {
    border: none;
    background-color: transparent;
    float: right;
  }
  img {
    width: 15px;
  }
  th:last-child {
    text-align: right;
  }
  th:first-child {
    text-align: left;
  }
  .tableNoResults {
    text-align: center;
  }
}

.filterResults {
  th:last-child {
    padding-bottom: 20px;
    text-align: right;
  }
  .input-group-text {
    border-right: 0px;
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .dropdown {
    border: 1px solid #ced4da;
    border-left: 0px;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;

    button {
      border-radius: 0px;
    }
    .copyLink {
      color: #1b1e2f;
    }
    button:hover, &.show button {
      background-color: #1b1e2f;
      .copyLink {
        color: #fff;
      }
    }
  }
  .form-control {
    padding-right: 35px;
    min-width: 200px;
    max-width: fit-content;
  }
}

.familyListCard {
  .filterResults {
    .input, .form-control {
      width: 225px;
      max-width: 225px !important;
    }
  }
}

.schedularCont {
  .accordion {
    width: 100%;
  }
  .card-header {
    background-color: #1b1e2f;
    color: white;
    text-align: center !important;
    cursor: pointer;
    background-image: url("./assets/images/dropdown-chevron-white.png");
  }

  .copyLink {
    margin-top: -4px;
    color: #1b1e2f;
    cursor: pointer;
  }
}

.copyLinkModal {
  .alert {
    text-align: center;
    margin: 0px;

    .copyLink {
      margin-top: -3px;
      margin-right: 10px;
    }
  }
}

.familyAccordion {
  margin-bottom: 0px;
  display: table;
  width: 100%;

  .card {
    background: none;
    border: none !important;

    .card-header {
      padding: 0px;
      padding-bottom: 10px;
      border: none;
    }
    .collapse.show {
      border-top: 1px solid rgba(0,0,0,.125);
    }
    .card-body {
      padding: 15px;
      border: none;
    }
  }
}

.santAccordion {
  .modal-header .close {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.tableDesktop {
  display: inline-table;
  td, th {
    word-wrap: break-word;
    max-width: 300px;
  }
}
.tableMobile {
  display: none;
}

.printCont {
  background-image: none !important;

  .copyLink {
    color: white;
    float: right;
    margin-top: 0px;

    &.refresh {
      float: left;
    }
  }
}

@media (max-width: 670px) {
  .tableDesktop {
    z-index: -1;
    position: absolute;
  }
  .tableMobile {
    display: inline-table;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: 100vh;
    overflow: visible;
  }
  .filterResults {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  .tableDesktop {
    display: inline-table !important;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

.multiEventDetailsCont {
  align-items: center;
  // border: 1px solid;
  margin-bottom: 20px;
  label {
    font-weight: normal;
    margin: 0px;
    display: block;
  }
  span {
    font-size: 12px;
    // color: #34495e;
    font-style: italic;
    font-weight: bold;
  }
  .form-check {
    display: flex;
    input {
      margin: 0px;
    }
  }
}


/* Mukpath View CSS */
.mukpathPage {
  margin-top: -60px;
  background-color: #341F3C;
  color: #ffffff;
  height: 100vh;

  .navbar {
    background-color: #4B2A57;

    .nav-link {
      font-size: 1.3rem;
      font-weight: bold;
      color: white !important;
      text-transform: uppercase;
    }
  }

  .contentCont {
    margin: 20px;

    .mainCard {
      margin: 10px 0px;
      height: 100%;
      display: flex;

      .badge {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          height: 60px;
          width: 175px;
          border: 1px solid;
          display: block;
          font-size: 24px;
          line-height: 60px;
          margin-top: -10px;
          background-color: #ffffff;
          color: #000000;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
      .floral {
        width: 100px;
        height: auto;
        margin: 20px 0px;
      }
      .subCards {
        
        .profile-image {
          width: 250px;
          height: 250px;
        }
        h2 {
          margin: 0;
          font-size: 1.75em !important;
          span {
            display: inline-block;
            margin-top: 10px;
            font-size: 24px;
          }
        }
      }
    }
    .custom-backdrop {
      background: rgba(255, 255, 255, 0.1); /* Light color with transparency */
      border-radius: 25px; /* Smooth rounded corners */
      backdrop-filter: blur(10px); /* Apply blur effect */
      -webkit-backdrop-filter: blur(10px); /* Safari support */
      border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
      padding: 20px 25px;
    }

    .subCards {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .profile-image {
        width: 180px;  /* Fixed square width */
        height: 180px; /* Fixed square height */
        border: 4px solid white; /* Thick white border */
        object-fit: cover; /* Maintains aspect ratio while filling square */
        border-radius: 50%;
        padding: 8px !important; 
      }

      h2 {
        text-align: center;
        margin-bottom: 0px;

        span {
          color: #ffffff;
          opacity: .7;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }
  .slideImage {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  .fade-out {
    opacity: 0;
    animation-name: fadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}